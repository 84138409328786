<template>
  <customer-member-tab>
    <template v-slot:content>
      <v-form ref="form" v-model="valid" lazy-validation>
        <!-- Search conditions -->
        <v-card max-width="1400px" class="ml-10 mt-4">
          <div class="list-all-container pt-5 mb-10">
            <h2 style="text-align: center" class="my-2 title">検索条件</h2>

            <!-- Member information -->
            <h2 class="my-4 form-title">会員情報</h2>
            <v-row class="d-flex">
              <v-col cols="8" class="float-left d-flex justify-space-between">
                <v-text-field
                  dense
                  label="会員番号(コンマ,区切りで複数検索)"
                  v-model="filterData.memberIds"
                  :rules="[$rules.checkLenghInput(255)]"
                  class="text-field"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex">
              <v-col cols="3" class="float-left">
                <v-select
                  dense
                  hide-details
                  :items="itemsData.membershipStatus"
                  item-value="id"
                  item-text="name"
                  v-model="filterData.membershipStatus"
                  label="退会状態"
                  class="text-select"
                ></v-select>
              </v-col>
              <v-col cols="6" class="float-left d-flex justify-start">
                <DatePickerSelectFromTo
                  :dates="membershipCancellationDate"
                  :dateFrom="membershipCancellationDate[0].date"
                  :dateTo="membershipCancellationDate[1].date"
                  @change="updateMembership"
                  :attrTagInput="{
                    dense: true,
                    'hide-details': true,
                    label: '退会日',
                    className: 'color-calender',
                    type: 'text-field',
                    hasBtnResetDatePicker: true,
                  }"
                  @reset="updateMembership"
                ></DatePickerSelectFromTo>
              </v-col>
            </v-row>
            <v-row class="d-flex">
              <v-col cols="3" class="float-left">
                <v-select
                  dense
                  hide-details
                  :items="itemsData.requestMemberMagazineMail"
                  item-value="id"
                  item-text="name"
                  v-model="filterData.requestMemberMagazineMail"
                  label="会報誌送付"
                  class="text-select"
                ></v-select>
              </v-col>
              <v-col cols="3" class="float-left ml-8">
                <v-select
                  dense
                  hide-details
                  :items="itemsData.requestBrochureMail"
                  item-value="id"
                  item-text="name"
                  v-model="filterData.requestBrochureMail"
                  label="利用ガイド送付"
                  class="text-select"
                ></v-select>
              </v-col>
            </v-row>
            <!-- End member information -->

            <!-- Basic information -->
            <h2 class="my-4 form-title">基本情報</h2>
            <v-row>
              <v-col cols="3">
                <v-select
                  dense
                  hide-details
                  :items="itemsData.includeCompany"
                  item-value="id"
                  item-text="name"
                  v-model="corporateIndividualClassification"
                  placeholder="法人個人区分"
                  class="mt-3 text-select"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  class="mt-3 ml-2 text-field"
                  dense
                  label="顧客番号(コンマ,区切りで複数検索)"
                  :rules="[$rules.clientIdsComma]"
                  style="display: block !important"
                  v-model="clientIds"
                >
                  <!-- v-model="filterData.customerCode" -->
                </v-text-field>
              </v-col>
            </v-row>

            <v-card class="mt-10" outlined max-width="95%">
              <div class="list-all-container my-10">
                <v-row>
                  <v-col cols="6" class="float-left">
                    <v-row>
                      <v-col cols="6" class="float-left">
                        <span class="select-label">連絡先発送先</span>
                        <v-select
                          dense
                          hide-details
                          :items="itemsData.clientContactType"
                          item-value="id"
                          item-text="name"
                          v-model="filterData.clientContactType"
                          class="text-select"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" class="float-left">
                        <v-text-field
                          dense
                          label="郵便番号(コンマ,区切りで複数検索)"
                          v-model="filterData.contactPostalCodes"
                          :rules="rules.postalCode"
                          class="text-field"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="float-left">
                        <v-select
                          dense
                          hide-details
                          :items="itemsData.prefectureList"
                          item-text="name"
                          item-value="name"
                          v-model="filterData.contactPrefectures"
                          label="都道府県"
                          multiple
                          class="text-select"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="float-left">
                        <v-text-field
                          dense
                          label="市区郡(コンマ,区切りで複数検索)"
                          v-model="filterData.contactLocalities"
                          :rules="[$rules.checkLenghInput(255)]"
                          class="text-field"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-card>
            <!-- End basic information -->

            <!--  -->
            <!-- Prospect information -->
            <h2 class="my-4 form-title">見込客情報</h2>
            <v-row>
              <v-col cols="4" class="float-left">
                <span class="select-label">買増評価</span>

                <v-select
                  dense
                  hide-details
                  :items="itemsData.purchaseRateList"
                  item-value="id"
                  item-text="name"
                  v-model="filterData.furtherPurchaseRates"
                  multiple
                  class="text-select"
                ></v-select>
              </v-col>
              <v-col cols="4" class="float-left">
                <span class="select-label">更新評価</span>

                <v-select
                  dense
                  hide-details
                  :items="itemsData.purchaseRateList"
                  item-value="id"
                  item-text="name"
                  v-model="filterData.renewPurchaseRates"
                  multiple
                  class="text-select"
                ></v-select>
              </v-col>
            </v-row>
            <v-checkbox
              v-model="filterData.includeDoNotContact"
              label="折衝不可を含める"
              class="text-checkbox"
            ></v-checkbox>
            <v-row>
              <v-col cols="2" class="float-left">
                <v-select
                  dense
                  hide-details
                  :items="itemsData.picIds"
                  item-value="id"
                  item-text="name"
                  v-model="filterData.picIds"
                  label="営業担当"
                  multiple
                  class="text-select"
                ></v-select>
              </v-col>
            </v-row>
            <!-- end prospect information -->

            <!-- Contract information -->
            <h2 class="my-4 form-title">契約情報</h2>
            <v-row class="d-flex">
              <v-col cols="3" class="float-left d-flex justify-space-between">
                <v-select
                  dense
                  hide-details
                  :items="itemsData.hasProductTypeIds"
                  item-value="id"
                  item-text="code"
                  v-model="filterData.hasProductTypeIds"
                  label="所持契約"
                  multiple
                  class="text-select"
                ></v-select>
              </v-col>
              <v-col cols="2" class="float-left d-flex justify-space-between">
                <v-select
                  dense
                  hide-details
                  :items="itemsData.hasProductTypeIdsType"
                  item-value="id"
                  item-text="name"
                  v-model="filterData.hasProductTypeIdsType"
                  class="text-select"
                ></v-select>
              </v-col>
              <v-col cols="5" class="float-left d-flex justify-start">
                <DatePickerSelectFromTo
                  :dates="hasProductExpiringFromDate"
                  :dateFrom="hasProductExpiringFromDate[0].date"
                  :dateTo="hasProductExpiringFromDate[1].date"
                  @change="updateProductExpiring"
                  :attrTagInput="{
                    dense: true,
                    'hide-details': true,
                    label: '満了日',
                    className: 'color-calender',
                    type: 'text-field',
                    hasBtnResetDatePicker: true,
                  }"
                  @reset="updateProductExpiring"
                ></DatePickerSelectFromTo>
              </v-col>
              <v-col>
                <template>
                  <v-checkbox
                    class="mt-0"
                    hide-details
                    label="終了契約を含む"
                    v-model="filterData.hasProductTypeIdsIncludeExpired"
                  >
                  </v-checkbox>
                  <!-- color="#13ACE0" -->
                </template>
              </v-col>
            </v-row>
            <!-- End contract information -->

            <!-- Start does Not Have Product Type Ids -->
            <v-row>
              <v-col cols="3">
                <v-select
                  dense
                  hide-details
                  :items="itemsData.hasProductTypeIds"
                  item-value="id"
                  item-text="code"
                  v-model="filterData.doesNotHaveProductTypeIds"
                  label="除外する所持契約"
                  multiple
                  class="text-select"
                ></v-select>
              </v-col>
            </v-row>
            <!-- End does Not Have Product Type Ids -->

            <!-- MW search -->
            <p class="text--title textBlack my-4">MW検索</p>
            <v-row class="d-flex">
              <v-col cols="3" class="float-left d-flex justify-space-between">
                <v-select
                  dense
                  hide-details
                  :items="years"
                  item-value="id"
                  item-text="name"
                  :clearable="true"
                  v-model="filterData.mwYearFrom"
                  label="利用権年"
                  class="text-select"
                ></v-select>
                <h5 class="mt-3 ml-2 text-from-to-date">から</h5>
              </v-col>
              <v-col cols="3" class="float-left d-flex justify-space-between">
                <v-select
                  dense
                  hide-details
                  :items="years"
                  item-value="id"
                  item-text="name"
                  :clearable="true"
                  v-model="filterData.mwYearTo"
                  label="利用権年"
                  class="text-select"
                ></v-select>
                <h5 class="mt-3 ml-2 text-from-to-date">まで</h5>
              </v-col>
            </v-row>
            <v-row class="d-flex">
              <v-col cols="3" class="float-left d-flex justify-space-between">
                <v-select
                  v-if="facilityList"
                  dense
                  hide-details
                  @input="selectFacilityMW($event)"
                  :items="facilityList"
                  item-text="name"
                  item-value="id"
                  v-model="filterData.mwFacilityIds"
                  label="購入施設"
                  multiple
                  class="text-select"
                ></v-select>
              </v-col>
              <v-col cols="3" class="float-left d-flex justify-space-between">
                <v-select
                  :disabled="listItemMW.length === 0"
                  dense
                  hide-details
                  :items="listItemMW"
                  item-text="name"
                  item-value="id"
                  v-model="filterData.mwRoomTypeIds"
                  label="購入部屋タイプ"
                  multiple
                  class="text-select"
                ></v-select>
              </v-col>
              <v-col cols="3" class="float-left d-flex justify-space-between">
                <v-select
                  dense
                  hide-details
                  :items="itemsData.mwRanks"
                  v-model="filterData.mwRanks"
                  label="購入ランク"
                  multiple
                  class="text-select"
                ></v-select>
              </v-col>
              <v-col cols="3" class="float-left d-flex justify-space-between">
                <v-select
                  dense
                  hide-details
                  :items="itemsData.mwWeekNos"
                  v-model="filterData.mwWeekNos"
                  label="購入週番号"
                  multiple
                  class="text-select"
                ></v-select>
              </v-col>
            </v-row>
            <!-- End MW search -->

            <!-- PW search -->
            <p class="text--title textBlack my-4">PW検索</p>
            <v-row class="d-flex">
              <v-col cols="3" class="float-left d-flex justify-space-between">
                <v-select
                  dense
                  hide-details
                  :items="years"
                  item-value="id"
                  item-text="name"
                  v-model="filterData.pwYearFrom"
                  :clearable="true"
                  label="利用権年"
                  class="text-select"
                ></v-select>
                <h5 class="mt-3 ml-2 text-from-to-date">から</h5>
              </v-col>
              <v-col cols="3" class="float-left d-flex justify-space-between">
                <v-select
                  dense
                  hide-details
                  :items="years"
                  item-value="id"
                  item-text="name"
                  :clearable="true"
                  v-model="filterData.pwYearTo"
                  label="利用権年"
                  class="text-select"
                ></v-select>
                <h5 class="mt-3 ml-2 text-from-to-date">まで</h5>
              </v-col>
            </v-row>
            <v-row class="d-flex">
              <v-col cols="3" class="float-left d-flex justify-space-between">
                <v-select
                  v-if="facilityList"
                  @input="selectFacilityPW($event)"
                  :items="facilityList"
                  item-text="name"
                  item-value="id"
                  dense
                  hide-details
                  v-model="filterData.pwFacilityIds"
                  label="購入施設"
                  multiple
                  class="text-select"
                ></v-select>
              </v-col>
              <v-col cols="3" class="float-left d-flex justify-space-between">
                <v-select
                  :disabled="listItemPW.length === 0"
                  dense
                  hide-details
                  :items="listItemPW"
                  item-text="name"
                  item-value="id"
                  v-model="filterData.pwRoomTypeIds"
                  label="購入部屋タイプ"
                  multiple
                  class="text-select"
                ></v-select>
              </v-col>
              <v-col cols="3" class="float-left d-flex justify-space-between">
                <v-select
                  dense
                  hide-details
                  :items="itemsData.pwWeekNos"
                  v-model="filterData.pwWeekNos"
                  label="購入週番号"
                  multiple
                  class="text-select"
                ></v-select>
              </v-col>
            </v-row>
            <!-- End PW search -->

            <!-- VM search -->
            <p class="text--title textBlack my-4">VM検索</p>
            <v-row class="d-flex">
              <v-col cols="3" class="float-left d-flex justify-space-between">
                <v-select
                  dense
                  hide-details
                  :items="years"
                  item-value="id"
                  item-text="name"
                  :clearable="true"
                  v-model="filterData.vmYearFrom"
                  label="利用権年"
                  class="text-select"
                ></v-select>
                <h5 class="mt-3 ml-2 text-from-to-date">から</h5>
              </v-col>
              <v-col cols="3" class="float-left d-flex justify-space-between">
                <v-select
                  dense
                  hide-details
                  :items="years"
                  item-value="id"
                  item-text="name"
                  :clearable="true"
                  v-model="filterData.vmYearTo"
                  label="利用権年"
                  class="text-select"
                ></v-select>
                <h5 class="mt-3 ml-2 text-from-to-date">まで</h5>
              </v-col>
            </v-row>
            <v-row class="d-flex">
              <v-col cols="3" class="float-left d-flex justify-space-between">
                <v-select
                  v-if="facilityList"
                  @input="selectFacilityVM($event)"
                  :items="facilityList"
                  item-text="name"
                  item-value="id"
                  dense
                  hide-details
                  v-model="filterData.vmFacilityIds"
                  label="購入施設"
                  multiple
                  class="text-select"
                ></v-select>
              </v-col>
              <v-col cols="3" class="float-left d-flex justify-space-between">
                <v-select
                  :disabled="listItemVM.length === 0"
                  dense
                  hide-details
                  :items="listItemVM"
                  item-text="name"
                  item-value="id"
                  v-model="filterData.vmRoomTypeIds"
                  label="部屋タイプ"
                  multiple
                  class="text-select"
                ></v-select>
              </v-col>
            </v-row>
            <!-- End VM search -->

            <!-- Activity information -->
            <h2 class="my-4 form-title">活動情報</h2>
            <div class="d-flex">
              <DatePickerSelectFromTo
                :dates="lastActionDateFrom"
                :dateFrom="lastActionDateFrom[0].date"
                :dateTo="lastActionDateFrom[1].date"
                @change="updateLastActionDate"
                :attrTagInput="{
                  dense: true,
                  'hide-details': true,
                  label: '折衝日',
                  className: 'color-calender',
                  type: 'text-field',
                  hasBtnResetDatePicker: true,
                }"
                @reset="updateLastActionDate"
              ></DatePickerSelectFromTo>
              <div>
                <v-select
                  dense
                  hide-details
                  :items="itemsData.hasActionMethodIds"
                  item-value="id"
                  item-text="name"
                  v-model="filterData.hasActionMethodIds"
                  label="活動情報"
                  multiple
                  class="text-select ml-6"
                  style="min-width: 200px; max-width: 660px"
                ></v-select>
              </div>
              <div>
                <v-select
                  dense
                  hide-details
                  :items="itemsData.hasActionMethodIdsType"
                  item-value="id"
                  item-text="name"
                  v-model="filterData.hasActionMethodIdsType"
                  label="を全て含む"
                  style="min-width: 110px; max-width: 110px"
                  class="text-select ml-3"
                ></v-select>
              </div>
              <div>
                <v-select
                  dense
                  hide-details
                  :items="['指定なし', '対応内容あり', '対応内容なし']"
                  label="対応内容"
                  style="min-width: 196px; max-width: 196px"
                  class="text-select ml-3"
                ></v-select>
              </div>
            </div>
            <!-- End activity information -->

            <!-- Advertising measures -->
            <h2 class="my-4 form-title">広告施策(顧客)</h2>
            <v-row class="d-flex">
              <v-col cols="6" class="float-left d-flex justify-space-between">
                <v-dialog
                  v-model="dialog"
                  width="1330px"
                  class="advertising-measures-customer-dialog"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-combobox
                      label="広告施策名"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      class="combobox"
                      :value="selectedList"
                    ></v-combobox>
                  </template>
                  <v-card height="auto" style="padding-bottom: 1rem">
                    <AdvertisingMeasuresCustomersList
                      @close="dialog = false"
                      :selectedList="selectedList"
                      @onSave="checkSelectedList"
                    />
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row class="d-flex">
              <v-col cols="6" class="float-left d-flex justify-space-between">
                <v-select
                  dense
                  hide-details
                  :items="itemsData.campaignGroups"
                  v-model="filterData.campaignGroups"
                  label="広告施策タグ"
                  multiple
                  class="text-select"
                ></v-select>
              </v-col>
              <v-col cols="3" class="float-left d-flex justify-space-between">
                <v-select
                  dense
                  hide-details
                  :items="itemsData.campaignTimes"
                  item-value="id"
                  item-text="name"
                  v-model="filterData.campaignTimes"
                  label="広告施策回数"
                  class="text-select"
                ></v-select>
              </v-col>
            </v-row>
            <!-- End advertising measures -->

            <!-- Experience reservation -->
            <h2 class="my-4 form-title">体験予約</h2>
            <v-row class="d-flex">
              <v-col cols="3" class="float-left d-flex justify-space-between">
                <v-select
                  dense
                  hide-details
                  :items="itemsData.hasTrialStay"
                  item-value="id"
                  item-text="name"
                  v-model="filterData.hasTrialStay"
                  label="体験予約"
                  class="text-select"
                ></v-select>
              </v-col>
            </v-row>
            <!-- End experience reservation -->

            <!-- Customer information Remarks -->
            <h2 class="my-4 form-title">顧客情報備考</h2>
            <v-row class="d-flex">
              <v-col cols="5" class="float-left d-flex justify-space-between">
                <v-text-field
                  dense
                  label="顧客情報備考"
                  v-model="filterData.remarks"
                  :rules="[$rules.checkLenghInput(255)]"
                  class="text-field"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <!-- End customer information Remarks -->
            <div class="d-flex justify-center fixed-bottom">
              <v-btn
                :loading="searching"
                href="#table"
                class="t-btn--prm px-16 my-5 ctrl-enter-click"
                @click="
                  () => {
                    $refs.table.resetPageAndSearch();
                  }
                "
              >
                検索
              </v-btn>
            </div>
            <!--  -->
          </div>
        </v-card>
        <!-- End search conditions -->
        <div class="mx-10 mb-16">
          <div class="d-flex justify-start mb-2">
            <v-btn
              @click="downloadCsv()"
              :disabled="!checkPerUser"
              outlined
              small
              class="btn-crm-primary"
              >選択項目をCSV出力</v-btn
            >
            <v-btn
              outlined
              small
              class="btn-crm-primary ml-5"
              @click="setDefaultData()"
              ><span class="text-10px">選択項目をラベル印刷</span></v-btn
            >
          </div>
          <!-- :items="facilities" -->
          <Table
            :attr="{
              dense: true,
              'item-key': 'id',
              id: 'table',
              class: 'elevation-1 no-pagination',
            }"
            ref="table"
            :items="searchClientListMemberData"
            :headers="headers"
            :itemsPerPage="10"
            :total="getTotalItem"
            :itemsPerPageOptions="[10, 20, 50, 100, 200, 500]"
            :multiSort="true"
            :showSelect="true"
            :funReset="searchWrapper"
            :filter="parsedFilter"
            :sortField="[]"
            @setSelected="
              v => {
                selected = v;
              }
            "
            class="custom-table"
          >
            <template v-slot:[`item.PossessionContract`]="{ item }">
              <div v-for="(val, i) in item.contracts" :key="i">
                <span style="padding: 0 16px">{{ val.productType.code }}</span>
              </div>
            </template>
            <template v-slot:[`item.Content`]="{ item }">
              <contract-room-type v-for="(val, i) in item.contracts" :key="i" :contract="val" />
            </template>
            <template v-slot:[`item.basePoint`]="{ item }">
              <div v-for="(val, i) in item.contracts" :key="i">
                <span v-if="val.basePoint" style="padding: 0 16px">{{
                  val.basePoint
                }}</span>
                <span v-else>
                  <div style="height: 36px; line-height: 36px"></div>
                </span>
              </div>
            </template>
            <template v-slot:[`item.DetailsContract`]="{ item }">
              <div v-for="(val, i) in item.contracts" :key="i">
                <!-- {{ val.id }} -->
                <v-btn
                  icon
                  class="t-btn--prm"
                  width="26px"
                  height="26px"
                  @click="openContractDetail(val)"
                >
                  <v-icon class="icon-btn" size="17px"
                    >mdi-card-account-details</v-icon
                  >
                </v-btn>
              </div>
            </template>
            <template v-slot:[`item.ContractStartDate`]="{ item }">
              <div v-for="(val, i) in item.contracts" :key="i">
                <span v-if="val.startDate" style="padding: 0 16px">
                  {{ formatDate(val.startDate) }}
                </span>
                <span v-else>
                  <div style="height: 36px; line-height: 36px"></div>
                </span>
              </div>
            </template>
            <template v-slot:[`item.ContractEndDate`]="{ item }">
              <div v-for="(val, i) in item.contracts" :key="i">
                <span v-if="val.endDate" style="padding: 0 16px">
                  {{ formatDate(val.endDate) }}
                </span>
                <span v-else>
                  <div style="height: 36px; line-height: 36px"></div>
                </span>
              </div>
            </template>
            <template v-slot:[`item.ContractYears`]="{ item }">
              <div v-for="(val, i) in item.contracts" :key="i">
                <span v-if="val.years" style="padding: 0 16px">
                  {{ val.years }}
                </span>
                <span v-else>
                  <div style="height: 36px; line-height: 36px"></div>
                </span>
              </div>
            </template>
            <template v-slot:[`item.MembershipDeadline`]="{ item }">
              <span>{{
                getMaxEndDate(item.contracts.map(c => c.endDate))
              }}</span>
            </template>
            <template v-slot:[`item.Address`]="{ item }">
              <template v-if="item.mainContact">
                <span v-if="item.mainContact.postalCode">{{
                  item.mainContact.postalCode
                }}</span>
                <span v-if="item.mainContact.prefecture">
                  <span v-if="item.mainContact.postalCode">&nbsp;</span>
                  {{ item.mainContact.prefecture }}</span
                >
                <span v-if="item.mainContact.locality">
                  <span
                    v-if="
                      item.mainContact.postalCode || item.mainContact.prefecture
                    "
                    >-</span
                  >
                  {{ item.mainContact.locality }}</span
                >
                <span v-if="item.mainContact.address1">
                  <span
                    v-if="
                      item.mainContact.postalCode ||
                        item.mainContact.prefecture ||
                        item.mainContact.locality
                    "
                    >-</span
                  >
                  {{ item.mainContact.address1 }}</span
                >
                <span v-if="item.mainContact.address2">
                  <span
                    v-if="
                      item.mainContact.postalCode ||
                        item.mainContact.prefecture ||
                        item.mainContact.locality ||
                        item.mainContact.address1
                    "
                    >-</span
                  >
                  {{ item.mainContact.address2 }}</span
                >
              </template>
            </template>

            <template v-slot:[`item.isCompany`]="{ item }">
              <span v-if="item.isCompany === true">法人</span>
              <span v-else>個人</span>
            </template>
            <template v-slot:[`item.CustomerDetails`]="{ item }">
              <v-btn
                icon
                class="t-btn--prm"
                target="_blank"
                @click="openCustomerDetail(item)"
                width="26px"
                height="26px"
              >
                <v-icon size="17px"> mdi-human-handsup</v-icon>
              </v-btn>
            </template>
          </Table>
        </div>
      </v-form>
      <label-printing
        @change-arrIdEndPage="checkIdStartPage"
        :visible="visible === 1"
        :selected="selected"
        @close="visible = -1"
      ></label-printing>
      <!-- End Open PDF in new tab -->
    </template>
  </customer-member-tab>
</template>

<script>
import DatePickerSelectFromTo from '@/components/Input/datePicker/DatePickerSelectFromTo.vue';
import CustomerMemberTab from '../customerMemberTab.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { mwWeekNos } from '@/constants/itemsSelect.js';
import { checkNumber } from '@/utils/validate';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import LabelPrinting from '@/views/customer/applicant/LabelPrinting.vue';
import AdvertisingMeasuresCustomersList from '@/views/customer/response/AdvertisingMeasuresCustomers/AdvertisingMeasuresCustomersList.vue';
import gql from 'graphql-tag';
import Table from '@/components/Table/index.vue';
import ContractRoomType from './contractRoomType.vue'

import {
  handlErrorView,
  getCopyErrorTextView,
  formatDate2,
} from '@/constants/functions';
import { downloadFile } from '@/utils/download';

export default {
  components: {
    CustomerMemberTab,
    LabelPrinting,
    AdvertisingMeasuresCustomersList,
    DatePickerSelectFromTo,
    Table,
    ContractRoomType
  },
  name: 'ApplicantAll',
  data() {
    return {
      searching: false,
      listItemMW: [],
      listItemPW: [],
      listItemVM: [],
      facilityList: null,
      dataSelect: [],
      arrIdEndPage: [],
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      dialog: false,
      valid: true,
      visible: -1,
      rules: {
        number: [
          v => (v && checkNumber(v)) || !v || this.$t('rules.numberInvalid'),
          v =>
            (v && v <= 2147483647) || !v || this.$t('rules.maximumNumberInt32'),
        ],
        // text: [
        //   (v) =>
        //     (v && (CheckLenghInput(v, 255))) ||
        //     !v ||
        //     this.$t('rules.maximumNCharacter', { value: 255 }),
        // ],
        postalCode: [
          v => (v && checkNumber(v)) || !v || this.$t('rules.numberInvalid'),
          v =>
            (v && v.length === 7) ||
            (v && v.length === undefined) ||
            !v ||
            this.$t('rules.includeNCharacters', { value: 7 }),
        ],
      },
      searchClientListMemberData: [],
      itemsData: {
        // 基本情報
        includeCompany: [
          { id: true, name: '指定なし' },
          { id: null, name: '法人のみ' },
          { id: false, name: '個人のみ' },
        ],

        // 連絡先発送先
        clientContactType: [
          { id: null, name: '全て' },
          { id: 'HomeOrAddress', name: '自宅（個人）会社情報（法人）' },
          { id: 'WorkplaceOrNotary', name: '勤務先（個人）' },
          { id: 'Contact', name: '連絡先（個人・法人）' },
          { id: 'Other', name: 'その他（個人・法人）' },
        ],
        prefectureList: [],

        // 見込客情報
        purchaseRateList: [],
        picIds: [],

        // 会員情報
        membershipStatus: [
          { id: 'HasHadContract', name: '退会済みを含む' },
          { id: 'OnlyCurrent', name: '退会済みを含まない' },
          { id: 'OnlyExMember', name: '退会済みのみ' },
        ],
        requestMemberMagazineMail: [
          { id: null, name: '指定なし' },
          { id: true, name: 'チェックあり' },
          { id: false, name: 'チェックなし' },
        ],
        requestBrochureMail: [
          { id: null, name: '指定なし' },
          { id: true, name: 'チェックあり' },
          { id: false, name: 'チェックなし' },
        ],

        // 契約情報
        hasProductTypeIds: [],
        hasProductTypeIdsType: [
          { id: 'Any', name: 'のいずれかを含む' },
          { id: 'All', name: 'を全て含む' },
        ],

        // MW検索
        // mwYearFrom: this.years(),
        // mwYearTo: [2022],
        mwFacilityIds: [1, 2, 3],
        mwRoomTypeIds: [1, 2, 3],

        mwRanks: ['A', 'B', 'C'],
        mwWeekNos: mwWeekNos,

        // PW検索
        // pwYearFrom: [2021],
        // pwYearTo: [2022],
        pwFacilityIds: [1, 2, 3],
        pwRoomTypeIds: [1, 2, 3],
        pwWeekNos: mwWeekNos,

        // VM検索
        // vmYearFrom: [2021],
        // vmYearTo: [2022],
        vmFacilityIds: [1, 2, 3],
        vmRoomTypeIds: [1, 2, 3],

        // 活動情報
        hasActionMethodIds: [],
        hasActionMethodIdsType: [
          { id: 'Any', name: 'のいずれかを含む' },
          { id: 'All', name: 'を全て含む' },
        ],

        // 広告施策(顧客)
        campaignIds: [],
        campaignGroups: [],
        campaignTimes: [
          { id: null, name: '指定なし' },
          { id: 'Once', name: '初回' },
          {
            id: 'MoreThanOnce',
            name: '2回以上',
          },
        ],

        // 体験予約
        hasTrialStay: [
          { id: null, name: '指定なし' },
          { id: true, name: '体験予約あり' },
          {
            id: false,
            name: '体験予約なし',
          },
        ],
      },
      clientIds: null,
      filterData: {
        // 基本情報
        includeCompany: null,
        includeIndividual: null,
        // customerCode: null,

        // 連絡先発送先
        clientContactType: null,
        contactPostalCodes: null,
        contactPrefectures: null,
        contactLocalities: null,

        // 見込客情報
        furtherPurchaseRates: null,
        renewPurchaseRates: null,
        includeDoNotContact: null,
        picIds: null,

        // 会員情報
        memberIds: null,
        membershipStatus: 'OnlyCurrent',
        requestMemberMagazineMail: null,
        requestBrochureMail: null,

        // 契約情報
        hasProductTypeIds: null,
        hasProductTypeIdsType: 'All',
        hasProductTypeIdsIncludeExpired: false,

        // 除外する所持契約
        doesNotHaveProductTypeIds: null,

        // MW検索
        mwYearFrom: null,
        mwYearTo: null,
        mwFacilityIds: null,
        mwRoomTypeIds: null,
        mwRanks: null,
        mwWeekNos: null,

        // PW検索
        pwYearFrom: null,
        pwYearTo: null,
        pwFacilityIds: null,
        pwRoomTypeIds: null,
        pwWeekNos: null,

        // VM検索
        vmYearFrom: null,
        vmYearTo: null,
        vmFacilityIds: null,
        vmRoomTypeIds: null,

        // 活動情報
        hasActionMethodIds: null,
        hasActionMethodIdsType: null,

        // 広告施策(顧客)
        campaignIds: null,
        campaignGroups: null,
        campaignTimes: null,

        // 体験予約
        hasTrialStay: null,

        // 顧客情報備考
        remarks: null,
      },
      selected: [],
      selectedList: [],

      // 会員情報
      menuMembershipCancellationDate: [],
      membershipCancellationDate: [
        { id: 1, date: null },
        { id: 2, date: null },
      ],

      // 契約情報
      menuContractInfor: [],
      hasProductExpiringFromDate: [
        { id: 1, date: null },
        { id: 2, date: null },
      ],

      // 活動情報
      menuActivityInformation: [],
      lastActionDateFrom: [
        { id: 1, date: null },
        { id: 2, date: null },
      ],
      headers: [
        {
          text: '契約 口数',
          value: 'contracts.length',
          align: 'center',
          width: '50px',
          sortable: false,
        },
        {
          text: '顧客番号',
          value: 'id',
          fieldName: 'client.id',
          align: 'center',
          width: '75px',
        },
        {
          text: '会員番号',
          value: 'memberId',
          fieldName: 'client.memberId',
          width: '70px',
        },
        {
          text: '顧客名',
          value: 'name',
          align: 'center',
          width: '100px',
          sortable: false,
        },
        {
          text: '所持契約',
          value: 'PossessionContract',
          width: '80px',
          fieldName: 'productType.code'
        },
        {
          text: '内容',
          value: 'Content',
          align: 'center',
          width: '70px',
          sortable: false,
        },
        {
          text: '年間 Pt数',
          value: 'basePoint',
          align: 'center',
          width: '50px',
          fieldName: 'contracts.basePoint'
        },
        {
          text: '契約 詳細',
          value: 'DetailsContract',
          align: 'center',
          width: '50px',
          sortable: false,
        },
        {
          text: '開始',
          value: 'ContractStartDate',
          align: 'center',
          width: '70px',
          fieldName: 'contracts.startDate'
        },
        {
          text: '終了',
          value: 'ContractEndDate',
          align: 'center',
          width: '70px',
          fieldName: 'contracts.endDate'
        },
        {
          text: '年数',
          value: 'ContractYears',
          align: 'center',
          width: '45px',
          fieldName: 'contracts.years'
        },
        {
          text: '会員期限',
          value: 'MembershipDeadline',
          width: '70px',
          sortable: false,
        },
        {
          text: '発送先宛名',
          value: 'mainContact.labelName',
          width: '90px',
          fieldName: 'mainContact.labelName'
        },
        {
          text: '発送先住所',
          value: 'Address',
          width: '170px',
          fieldName: 'mainContact.postalCode'
        },
        {
          text: '電話連絡先 電話番号',
          value: 'mainContact.tel',
          align: 'center',
          width: '150px',
          fieldName: 'mainContact.tel'
        },
        {
          text: '個人 /法人',
          value: 'isCompany',
          align: 'center',
          width: '50px',
          fieldName: 'client.isCompany'
        },
        {
          text: '買増評価',
          value: 'furtherPurchaseRate.name',
          width: '75px',
          fieldName: 'client.furtherPurchaseRateId'
        },
        {
          text: '更新評価',
          value: 'renewPurchaseRate.name',
          width: '75px',
          fieldName: 'client.renewPurchaseRateId'
        },
        {
          text: '顧客 詳細',
          value: 'CustomerDetails',
          align: 'center',
          width: '45px',
          sortable: false
        },
        {
          text: '営業担当',
          value: 'pic.name',
          width: '80px',
          fieldName: 'pic.name'
        },
      ],
      facilities: [],
      options: {
        page: 1,
        itemsPerPage: 10,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getTotalItem',
      'customerLengthPDF',
      'getSearchClientListMember',
      'enumPurchaseRateListApplicant',
    ]),
    years() {
      const arr = [];
      const yearNow = parseInt(new Date().getFullYear());
      const yearStart = yearNow + 10;
      const yearEnd = yearNow - 10;
      for (let i = yearEnd; i <= yearStart; i++) {
        arr.push({ id: i, name: i + '年' });
      }
      return arr;
    },

    corporateIndividualClassification: {
      get() {
        return '';
      },
      set(value) {
        if (value) {
          this.filterData.includeCompany = true;
          this.filterData.includeIndividual = true;
        } else if (value === null) {
          this.filterData.includeCompany = true;
          this.filterData.includeIndividual = false;
        } else {
          this.filterData.includeCompany = false;
          this.filterData.includeIndividual = true;
        }
        // this.filterData.includeCompany =
        // // 基本情報
        // : null,
        // includeIndividual: null,
      },
    },

    parsedClientIds() {
      if (this.clientIds) {
        return this.clientIds
          .split(',')
          .map(v => parseInt(v.trim(), 10))
          .filter(v => !!v);
      } else {
        return null;
      }
    },

    parsedFilter() {
      return {
        ...this.filterData,
        membershipCancellationDateFrom:
          this.membershipCancellationDate[0].date || null,
        membershipCancellationDateTo:
          this.membershipCancellationDate[1].date || null,
        hasProductExpiringFromDate:
          this.hasProductExpiringFromDate[0].date || null,
        hasProductExpiringToDate:
          this.hasProductExpiringFromDate[1].date || null,
        lastActionDateFrom: this.lastActionDateFrom[0].date || null,
        lastActionDateTo: this.lastActionDateFrom[1].date || null,
        clientIds: this.parsedClientIds,
        contactPostalCodes: this.filterData.contactPostalCodes || null,
        memberIds: this.filterData.memberIds
          ?.split(',')
          .filter(v => !!v.trim()),
      };
    },
    itemStart() {
      return (this.options.page - 1) * this.options.itemsPerPage + 1;
    },
    itemStop() {
      return Math.min(
        this.getTotalItem,
        this.options.page * this.options.itemsPerPage,
      );
    },
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.searchWrapper();
      },
    },
  },
  methods: {
    getCopyErrorTextView,
    downloadFile,
    ...mapActions([
      'actionSearchClientListMember',
      'enumPrefectureList',
      'getEnumPurchaseRateListApplicant',
      'proStaffList',
      'enumProductTypeList',
      'actionEnumClientActionMethodList',
      'getCampaignListResponseAll',
      'getEnumCampaignGroupListResponseAll',
      'actionEnumSignUpBonusList',
    ]),
    ...mapMutations([
      'setAlertError',
      'setCopyErrorText',
      'setCustomerLengthPDF',
    ]),
    openContractDetail(item) {
      let url = '';
      // const url = '/contract/basic-infomation';
      if (item.productType.code === 'VS') {
        url = '/contract/vs-vs';
      } else if (item.productType.code === 'PW') {
        url = '/contract/usage-situation-sp-pw';
      } else if (item.productType.code === 'MW') {
        url = '/contract/usage-situation-mw';
      } else if (item.productType.code === 'VM') {
        url = '/contract/usage-situation-vm';
      } else {
        url = '/contract/usage-situation-sp-pw';
      }
      const routeData = `${url}/${item.id}/${item.productType.code}?clientId=${item.clientId}&contractId=${item.id}`;
      window.open(routeData);
    },

    async getFacilityList() {
      await this.$apollo
        .query({
          query: gql`
            query {
              facilityList {
                id
                name
                roomTypes {
                  id
                  name
                }
              }
            }
          `,
        })
        .then(data => {
          this.facilityList = data.data.facilityList;
        })
        .catch(async error => {
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },

    selectFacilityMW(event) {
      this.filterData.mwRoomTypeIds = [];
      this.listItemMW = [];
      if (event !== []) {
        event.map(idFacility => {
          this.listItemMW = this.listItemMW.concat(
            this.facilityList.find(i => i.id === idFacility).roomTypes,
          );
        });
      }
    },

    selectFacilityPW(event) {
      this.filterData.pwRoomTypeIds = [];
      this.listItemPW = [];
      if (event !== []) {
        event.map(idFacility => {
          this.listItemPW = this.listItemPW.concat(
            this.facilityList.find(i => i.id === idFacility).roomTypes,
          );
        });
      }
    },
    selectFacilityVM(event) {
      this.filterData.vmRoomTypeIds = [];
      this.listItemVM = [];
      if (event !== []) {
        event.map(idFacility => {
          this.listItemVM = this.listItemVM.concat(
            this.facilityList.find(i => i.id === idFacility).roomTypes,
          );
        });
      }
    },

    async downloadCsv() {
      if (this.selected.map(e => e.id).length > 0) {
        const query = `
              mutation ($clientIds: [Int!]!) {
                clientListCsv(clientIds: $clientIds) {
                  files {
                    fileName
                    downloadUrl
                  }
                }
              }
            `;
        const variables = {
          clientIds: this.selected.map(e => e.id),
        };
        await this.$apollo
          .mutate({
            mutation: gql`
              ${query}
            `,
            variables: variables,
            fetchPolicy: 'no-cache',
          })
          .then(data => {
            this.downloadFile(
              data.data.clientListCsv.files[0].downloadUrl,
              data.data.clientListCsv.files[0].fileName,
            );
          })
          .catch(async error => {
            this.setCopyErrorText(
              this.getCopyErrorTextView(query, variables, error.graphQLErrors),
            );
            const errorTmp = await handlErrorView(
              error.graphQLErrors,
              this.setPermissionUser,
              this.setRoleAdminUser,
            );
            if (errorTmp) {
              this.setAlertError(errorTmp, { root: true });
            } else {
              this.setAlertError('削除に失敗しました');
            }
          });
      }
    },

    enterSelect() {},

    openCustomerDetail(item) {
      window.open(`/customer-details/${item.id}?typeClient=${item.type}`);
    },

    setDefaultData() {
      this.setCustomerLengthPDF(0);
      this.checkIdStartPage();
      this.visible = 1;
    },

    checkIdStartPage() {
      var arr = [];
      if (this.customerLengthPDF > 0) {
        for (let i = 1; i < this.customerLengthPDF; i++) {
          arr.unshift({
            birthday: '',
            companyPicDepartment: '',
            companyPicName: '',
            companyPicTitle: '',
            contracts: '',
            doNotContact: '',
            id: '',
            isCompany: '',
            mainContact: '',
            memberId: '',
            membershipCancellationDate: '',
            name: '',
            pic: '',
            purchaseRate: '',
            type: '',
          });
        }
      }
      this.selected.forEach(e => {
        arr.push(e);
      });
      this.dataSelect = arr;

      for (let i = 0; i < this.customerLengthPDF; i++) {
        if (this.arrIdEndPage.length === 0) {
          this.arrIdEndPage.push(11);
        } else {
          this.arrIdEndPage.push(this.arrIdEndPage[i - 1] + 12);
        }
      }
    },
    initFacilities() {
      for (let i = 0; i < 20; i++) {
        var dataTest = [
          {
            PossessionContract: 'MW',
            Contents: '京都3週禁煙',
            start: '2000年1月',
            end: '2019年12月',
            Years: '5',
          },
          {
            PossessionContract: 'PW',
            Contents: '',
            start: '2000年1月',
            end: '2019年12月',
            Years: '5',
          },
          {
            PossessionContract: 'SP',
            Contents: '',
            start: '2000年1月',
            end: '2019年12月',
            Years: '5',
          },
        ];

        this.facilities.push({
          id: i,
          NumberOfContracts: 3,
          CustomerID: '0000000012',
          MembershipNumber: '0000000012',
          CustomerName: i % 2 === 0 ? '東急太郎' : '東急株式会社XXXXX',
          dataTest: dataTest,

          age: i % 2 === 0 ? '50' : '',
          PurchaseEvaluation: '契約完了',
          inPerson: i % 2 === 0 ? '有' : '無',
          Experience: i % 2 === 0 ? '有' : '無',
          ShippingAddress: '150-0002 渋谷区渋谷1-16-14 150-0002',
          NoNegotiation: i % 2 === 0 ? '折衝不可' : '',
          PrivateCorporation: i % 2 === 0 ? '個人' : '法人',

          WithdrawalStatus: i % 2 === 0 ? '退会済み' : '',
          SalesStaff: i % 2 === 0 ? '今津康平' : '東急太郎',
        });
      }
    },
    formatDate(date) {
      return formatDate2(date);
    },
    getMaxEndDate(dates) {
      const max = dates.reduce((acc, date) => {
        return acc && new Date(acc) > new Date(date) ? acc : date;
      }, '');
      if (max) {
        return formatDate2(max);
      }
    },
    async resetPageAndSearch() {
      if (this.options.page === 1) {
        // page is already the 1st, so just search again
        return this.handleFilter();
      } else {
        // must reset the page and search.
        this.options.page = 1;
        // no need to invoke this.search() because the options watcher will do that
      }
    },

    async searchWrapper(variables) {
      if (variables) {
        const data = {
          filter: variables.filter,
          pagination: {
            take: variables.pagination.take,
            skip: variables.pagination.skip,
          },
          orderBy: variables.orderBy
        };
        try {
          const result = await this.actionSearchClientListMember(data);
          this.searchClientListMemberData = result;
        } finally {
        }
      }
    },
    handleFilter() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.searchWrapper();
      } else {
        this.setAlertError(this.$t('messages.searchFailed'));
      }
    },
    checkSelectedList(arr) {
      const newArr = [];
      const newArrId = [];
      // const newArrId = [];
      arr.forEach(element => {
        newArr.push(element.name);
        newArrId.push(element.id);
      });
      this.selectedList = newArr;
      this.filterData.campaignIds = newArrId;
    },

    updateMembership(item) {
      if (item.id === 1) {
        // console.log('go', item);
        this.membershipCancellationDate[0].date = item.date;
      } else {
        this.membershipCancellationDate[1].date = item.date;
      }
    },
    updateProductExpiring(item) {
      if (item.id === 1) {
        // console.log('go', item);
        this.hasProductExpiringFromDate[0].date = item.date;
      } else {
        this.hasProductExpiringFromDate[1].date = item.date;
      }
    },
    updateLastActionDate(item) {
      if (item.id === 1) {
        console.log('go', item);
        this.lastActionDateFrom[0].date = item.date;
      } else {
        this.lastActionDateFrom[1].date = item.date;
      }
    },
  },

  created() {
    this.getFacilityList();
  },

  mounted() {
    this.initFacilities();
    if (this.$refs.table) {
      this.$refs.table.reset();
    }
    this.enumPrefectureList().then(enumPrefectureList => {
      this.itemsData.prefectureList = enumPrefectureList;
    });
    this.getEnumPurchaseRateListApplicant().then(enumPurchaseRateList => {
      this.itemsData.purchaseRateList = enumPurchaseRateList;
    });
    this.proStaffList().then(staffs => {
      this.itemsData.picIds = staffs;
    });
    this.enumProductTypeList().then(enumProductTypeList => {
      this.itemsData.hasProductTypeIds = enumProductTypeList;
    });
    this.actionEnumClientActionMethodList().then(enumClientActionMethodList => {
      this.itemsData.hasActionMethodIds = enumClientActionMethodList;
    });
    this.getCampaignListResponseAll().then(enumCampaignMediaTypeList => {
      this.itemsData.campaignIds = enumCampaignMediaTypeList;
    });
    this.getEnumCampaignGroupListResponseAll().then(enumCampaignGroupList => {
      const newCampaignGroupList = [];
      enumCampaignGroupList.forEach(element => {
        newCampaignGroupList.push(element.name);
      });
      this.itemsData.campaignGroups = newCampaignGroupList;
    });
  },
};
</script>

<style scoped lang="scss">
.list-all-container {
  margin-right: 30px;
  margin-left: 30px;
}
.textBlack {
  font-size: 16px;
  color: black;
}
.select-label {
  font-size: 10px;
  color: #000000;
}
.title {
  font-size: 22px !important;
  color: #000000;
  font-weight: bold;
}
.form-title {
  color: #0c6786;
  font-size: 20px !important;
  font-weight: bold;
}
.text-select {
  ::v-deep {
    .v-select__selection--comma {
      color: #000000;
      font-weight: 500;
      font-size: 14px;
    }
    input::placeholder {
      color: #888888 !important;
      font-size: 14px;
      font-weight: 500;
      opacity: 1;
    }
  }
}
.text-field {
  ::v-deep {
    .v-text-field__slot {
      label {
        color: #888888;
        font-size: 14px;
        font-weight: 500;
      }
      input {
        color: #000000;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
.text-checkbox {
  ::v-deep {
    .v-input__slot {
      label {
        color: #000000;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
.icon-calendar {
  .v-icon {
    color: red !important;
  }
}
.text-from-to-date {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}
.custom-table {
  ::v-deep {
    .v-data-table__wrapper {
      thead {
        tr {
          th {
            padding: 0 8px !important;
            font-size: 14px !important;
            font-weight: 500 !important;
            color: #000000 !important;
            span {
              white-space: normal;
              word-wrap: break-word;
              word-break: break-word;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: 14px !important;
            font-weight: 500 !important;
            color: #000000 !important;
            padding: 0 8px !important;
            // 所持契約
            &:nth-child(6) {
              // max-width: 70px !important;
              padding: 0 !important;
              line-height: 36px;
              div:not(:last-child) {
                border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
              }
            }
            // 内容
            &:nth-child(7) {
              // max-width: 70px !important;
              padding: 0 !important;
              line-height: 36px;
              div:not(:last-child) {
                border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
              }
            }
            // 年間 Pt数
            &:nth-child(8) {
              // max-width: 50px !important;
              padding: 0 !important;
              line-height: 36px;
              div:not(:last-child) {
                border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
              }
            }
            // 契約 詳細
            &:nth-child(9) {
              // max-width: 50px !important;
              padding: 0 !important;
              line-height: 36px;
              div:not(:last-child) {
                border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
              }
            }
            // 開始
            &:nth-child(10) {
              // max-width: 70px !important;
              padding: 0 !important;
              line-height: 36px;
              div:not(:last-child) {
                border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
              }
            }
            // 終了
            &:nth-child(11) {
              // max-width: 70px !important;
              padding: 0 !important;
              line-height: 36px;
              div:not(:last-child) {
                border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
              }
            }
            // 年数
            &:nth-child(12) {
              // max-width: 45px !important;
              padding: 0 !important;
              line-height: 36px;
              div:not(:last-child) {
                border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
              }
            }
            // 会員期限
            &:nth-child(13) {
              // max-width: 70px !important;
              padding-left: 16px !important;
              padding-right: 16px !important;
              line-height: 36px;
              div:not(:last-child) {
                border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
              }
            }
          }
        }
      }
    }
    .v-data-footer {
      .v-data-footer__pagination {
        display: none !important;
      }
      color: #000000 !important;
      .v-select__selection--comma {
        color: #000000 !important;
      }
      .v-icon {
        color: #000000;
      }
    }
  }
}
div.div-postalCode {
  min-height: 22px;
  font-size: 10px;
}
div.div-address {
  min-height: 50px;
}
div#pdf {
  position: absolute;
  left: -1000000px;
  display: none;
  width: 757.13px;
  min-height: 1070.79px;
  // border: 1px solid black;
  padding: 80.13px 35.3px 82.02px 35.3px;
}
.div-item {
  // border: 1px solid black;
  border-radius: 10px;
  padding: 0px 10px;
  margin: 0px !important;
  min-height: 165px !important;
  width: 326.55 !important;
  font-size: 10px;
}
</style>
<style lang="scss">
html {
  scroll-behavior: smooth;
}
.pdf {
  &_item {
    position: relative;
    &_id {
      position: absolute;
      bottom: 15px;
      right: 15px;
    }
  }
}
@media print {
  .pdf {
    &_item {
      position: relative;
      &_id {
        position: absolute;
        bottom: 15px;
        right: 15px;
      }
    }
  }
}
.mdi-close {
  font-size: 15px !important;
}
.v-input__icon--clear {
  .v-icon__svg {
    height: 15px !important;
    width: 15px !important;
  }
}
</style>
